<template>
  <div v-if="dialog">

    <v-dialog
        :value="dialog"
        persistent
        transition="dialog-bottom-transition"
    >
      <v-card >
        <v-toolbar >
          <v-btn icon  @click="$emit('onCloseDialog')">
            <v-icon>mdi-close</v-icon>
          </v-btn>
          <v-toolbar-title> {{ $t('title.update_vip_percentage') + '  '   }}<span class="vip-percentage-label">{{payOutData.label}}</span></v-toolbar-title>
          <v-spacer></v-spacer>
          <v-toolbar-items>
            <v-btn
                :disabled="loading"
                
                text
                @click="validate"
            >
              {{ !payOutData._id ? $t('button.create') : $t('button.update') }}
            </v-btn>
          </v-toolbar-items>
        </v-toolbar>
        <Loading :visible="loading"/>
        <v-card-text>
          <v-container>
            <v-form
                ref="form"
                v-model="valid"
                lazy-validation
            >
              <v-row class="mt-5">
              </v-row>
                <v-row class="mt-5">
                <v-text-field
                    v-model="payOutData.percentage"
                    :label="$t('input.percentage_vip')"
                    :rules="rules.start_time"
                    type="number"
                />
              </v-row>
              
            </v-form>
          </v-container>
        </v-card-text>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
export default {

  props: {
    dialog: {
      type: Boolean,
      default: false
    },
    darkmode: {
      type: Boolean
    },
    optionRoles: {
      type: Array,
      default: () => {
        return []
      }
    },
    loading: {
      type: Boolean
    },
    rules: {
      type: Object,
      default: function () {
        return {
           start_time: v  => {
              if (!isNaN(parseFloat(v)) && v >= 0 && v <= 100) return true;
            return 'Number has to be between 0 and 100';
          },
          end_time: [
            v => !!v || 'End Time is required',
          ],
          total_bet_a_fight: [
            v => !!v || 'description is required',
          ],
          wala_name: [
            v => !!v || 'description is required',
          ],
          meron_name: [
            v => !!v || 'description is required',
          ],
        }
      }
    },
    isValidate: {
      type: Boolean,
      default: true
    },
    payOutData: {type: Object, default: null},
    users: {
      type: Array,
       default: null
    }
  },
  computed: {
    valid: {
      // getter
      get: function () {
        return this.isValidate
      },
      // setter
      set: function (newValue) {
        this.$emit('update:isValidate', newValue)
      }
    }
  },
  data: () => {
    return {
      showPassword: false,
      max: 24,
    }
  },
  watch: {
    optionRoles:function(newVal){
      this.users.role_id = newVal[0]
    },
  },
  methods: {
    validate() {
      if (this.$refs.form.validate()) {
        this.$emit('submit')
      }
    },
  }
}

</script>
<style scoped>
  .vip-percentage-label{
    color: cadetblue;
    font-size: 24px;
    font-weight: 800;
    padding-left:10px
  }
</style>